var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	body {\n		--mw-widget-brand-color: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"brandColor") || (depth0 != null ? lookupProperty(depth0,"brandColor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"brandColor","hash":{},"data":data,"loc":{"start":{"line":4,"column":27},"end":{"line":4,"column":41}}}) : helper)))
    + "\n	}\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"mw-widget__col mw-widget__destination is-hidden\">\n			<input type=\"hidden\" name=\"destination\" id=\"destination\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"destination") || (depth0 != null ? lookupProperty(depth0,"destination") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"destination","hash":{},"data":data,"loc":{"start":{"line":20,"column":67},"end":{"line":20,"column":82}}}) : helper)))
    + "\">\n			<input type=\"hidden\" name=\"destination_latlng\" id=\"destination_latlng\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"latlng") || (depth0 != null ? lookupProperty(depth0,"latlng") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"latlng","hash":{},"data":data,"loc":{"start":{"line":21,"column":81},"end":{"line":21,"column":91}}}) : helper)))
    + "\">\n		</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"mw-widget__col mw-widget__destination\">\n			<iframe id=\"destination-frame\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"baseUrl") || (depth0 != null ? lookupProperty(depth0,"baseUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"baseUrl","hash":{},"data":data,"loc":{"start":{"line":25,"column":39},"end":{"line":25,"column":50}}}) : helper)))
    + "/mw-widgets/destination\" frameborder=\"0\" title=\"Destination location\"></iframe>\n			<input type=\"hidden\" name=\"destination\" id=\"destination\">\n			<input type=\"hidden\" name=\"destination_latlng\" id=\"destination_latlng\">\n		</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<input type=\"hidden\" name=\"discount_coupon\" id=\"discount_coupon\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"coupon") || (depth0 != null ? lookupProperty(depth0,"coupon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"coupon","hash":{},"data":data,"loc":{"start":{"line":46,"column":75},"end":{"line":46,"column":85}}}) : helper)))
    + "\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"brandColor") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":6,"column":8}}})) != null ? stack1 : "")
    + "	.flatpickr-day.today {\n		border-color: var(--mw-widget-brand-color, "
    + alias4(((helper = (helper = lookupProperty(helpers,"brandColor") || (depth0 != null ? lookupProperty(depth0,"brandColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"brandColor","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":59}}}) : helper)))
    + ");\n	}\n	.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.today:hover, .flatpickr-day.today:focus {\n		background: var(--mw-widget-brand-color, "
    + alias4(((helper = (helper = lookupProperty(helpers,"brandColor") || (depth0 != null ? lookupProperty(depth0,"brandColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"brandColor","hash":{},"data":data,"loc":{"start":{"line":11,"column":43},"end":{"line":11,"column":57}}}) : helper)))
    + ");\n		border-color: var(--mw-widget-brand-color, "
    + alias4(((helper = (helper = lookupProperty(helpers,"brandColor") || (depth0 != null ? lookupProperty(depth0,"brandColor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"brandColor","hash":{},"data":data,"loc":{"start":{"line":12,"column":45},"end":{"line":12,"column":59}}}) : helper)))
    + ");\n	}\n</style>\n\n<div class=\"mw-widget__headline\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"heading") || (depth0 != null ? lookupProperty(depth0,"heading") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"heading","hash":{},"data":data,"loc":{"start":{"line":16,"column":33},"end":{"line":16,"column":46}}}) : helper))) != null ? stack1 : "")
    + "</div>\n<form action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"formUrl") || (depth0 != null ? lookupProperty(depth0,"formUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formUrl","hash":{},"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":17,"column":25}}}) : helper)))
    + "\" id=\"mw-widget-form-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":52}}}) : helper)))
    + "\" class=\"mw-widget__form\" method=\"GET\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"destination") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":18,"column":1},"end":{"line":29,"column":8}}})) != null ? stack1 : "")
    + "	<div class=\"mw-widget__col mw-widget__dates\">\n		<label for=\"arrival_date\" class=\"screen-reader-only\">Arrival Date</label>\n		<input type=\"date\" name=\"arrival_date\" id=\"arrival_date\" placeholder=\"dd/mm/aaaa\" class=\"mw-widget__form-input\" required>\n		<label for=\"landing_time\" class=\"screen-reader-only\">Landing Time</label>\n		<input type=\"time\" name=\"landing_time\" id=\"landing_time\" placeholder=\"hh:mm\" class=\"mw-widget__form-input\" required>\n	</div>\n	<div class=\"mw-widget__col mw-widget__adults\">\n		<label for=\"adults\" class=\"screen-reader-only\">Adults</label>\n		<input type=\"number\" name=\"adults\" id=\"adults\" placeholder=\"Adults\" class=\"mw-widget__form-input\" required>\n	</div>\n	<div class=\"mw-widget__col mw-widget__children\">\n		<label for=\"children\" class=\"screen-reader-only\">Children</label>\n		<input type=\"number\" name=\"children\" id=\"children\" placeholder=\"Children (0-12)\" class=\"mw-widget__form-input\">\n	</div>\n	<div class=\"mw-widget__col mw-widget__submit\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"coupon") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":2},"end":{"line":47,"column":9}}})) != null ? stack1 : "")
    + "		<button id=\"mw-widget__submit-btn\" type=\"submit\">\n			<span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"buttonText") || (depth0 != null ? lookupProperty(depth0,"buttonText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"buttonText","hash":{},"data":data,"loc":{"start":{"line":49,"column":9},"end":{"line":49,"column":23}}}) : helper)))
    + "</span>\n			<small>"
    + alias4(((helper = (helper = lookupProperty(helpers,"buttonLabel") || (depth0 != null ? lookupProperty(depth0,"buttonLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"buttonLabel","hash":{},"data":data,"loc":{"start":{"line":50,"column":10},"end":{"line":50,"column":25}}}) : helper)))
    + "</small>\n		</button>\n	</div>\n</form>\n<script src=\"https://cdn.jsdelivr.net/npm/time-input-polyfill\"></script>";
},"useData":true});