import flatpickr from "flatpickr";
require("flatpickr/dist/flatpickr.css");
require("../../dist/css/widget.css");
require("iframe-resizer/js/iframeResizer.min.js");

export default class mijoWidget {

	constructor(feedUrl, targetId) {
		this.baseUrl = feedUrl;
		this.feedUrl  = `${feedUrl}/mw-widgets/${targetId}/`;
		this.targetId = targetId;
	}

	// Target Element that will render the widget.
	targetHtmlEl = "";

	// Error messages.
	messages = {
		missingTargetId: "Please specify element target id",
		missingTargetHtml: "Not found HTML element with the specified id",
		missingFeedUrl: "Please specify your xml feed url",
		errorFetchingData: "Error reading data from the remote url"
	};

	validate() {

		// Check for an ID.
		if (!this.targetId) {
			console.log(this.messages.missingTargetId);

			return false;
		}

		// Check the feed url to be fetched.
		if (!this.feedUrl) {
			console.log(this.messages.missingFeedUrl);

			return false;
		}

		// Check if an element with our ID exists.
		this.targetHtmlEl = document.getElementById(this.targetId);

		if (!this.targetHtmlEl) {
			console.log(this.messages.missingTargetHtml);

			return false;
		}

		// Continue.
		return true;
	}

	render() {

		var self = this;

		// Validate the data.
		if (!this.validate()) {
			return;
		}

		// Fetch data.
		this.fetchData(function(response) {
			var loaded;

			if(response.state == 1) {
				var formTemplate = require('../templates/widget-form.handlebars');
				var container = document.createElement('div');
				container.classList.add('mw-widget');

				// Render handlebars template.
				container.innerHTML = formTemplate({
					id: response.data.id,
					baseUrl: self.baseUrl,
					heading: response.data.heading,
					destination: response.data.destination.address,
					latlng: `${response.data.destination.lat},${response.data.destination.lng}`,
					coupon: response.data.coupon,
					buttonText: response.data.button_text,
					buttonLabel: response.data.button_label,
					brandColor: response.data.brand_color,
					formUrl: response.data.form_url,
				});
				self.targetHtmlEl.appendChild(container);

				// Turn loaded flag.
				loaded = 1;
			} else {
				console.log(self.messages.errorFetchingData);
			}

			// Listen to messages from the iframe.
			window.addEventListener('message', function(evt){
				var trimmedUrl = evt.origin.replace(/^https?:/, '');

				// Return if not from parent site.
				if (trimmedUrl !== self.baseUrl) {
					return;
				}

				// Assign the values from the iframe autocomplete.
				if (evt.data.destination && evt.data.latlng) {
					document.getElementById('destination').value = evt.data.destination;
					document.getElementById('destination_latlng').value = evt.data.latlng;
				}
			}, false);

			// Only execute if the render has been done.
			if (loaded) {
				var form = document.getElementById(`mw-widget-form-${response.data.id}`);
				var destInput = document.getElementById('destination');
				var destLatLngInput = document.getElementById('destination_latlng');

				form.addEventListener('submit', function(evt) {
					// prevent form submit
					evt.preventDefault();

					// Validation for hidden fields before submitting.
					var errorCount = document.querySelectorAll('.mw-input-error');

					if ( !destInput.value && !destLatLngInput.value) {
						var errorMsg = document.createElement('span');
						errorMsg.classList.add('mw-input-error');
						errorMsg.innerHTML = 'Please, select a destination';

						// Show it only one time
						if (errorCount.length < 1) {
							document.querySelector('.mw-widget__destination').appendChild(errorMsg);
						}
					} else {
						// Remove it if error exists
						if (errorCount.length >= 1) {
							document.querySelector('.mw-input-error').remove();
						}

						// Submit
						form.submit();
					}
				});

				// Make iframe resizable.
				iFrameResize({log: false}, '#destination-frame');

				// Load picker for date and time fields.
				flatpickr('#arrival_date', {
					disableMobile: true,
					altInput: true,
					altInputClass: 'arrival_date_picker',
					altFormat: "F j, Y",
					dateFormat: "Y-m-d",
					allowInput: true,
					minDate: 'today',
				});
				flatpickr('#landing_time', {
					altInput: true,
					altInputClass: 'landing_time_picker',
					enableTime: true,
					noCalendar: true,
					altFormat: "h:i K",
					dateFormat: "H:i",
					defaultHour: 12,
					defaultMinute: 0
				});
			}

		});
	}

	fetchData(callback) {

		fetch(this.feedUrl)
			.then((response) => response.json())
			.then((data) => { callback(data) })
	}
}
