import mijoWidget from './mijo-widget';

function app(window) {
	// default configs if needed.
	let configs = {};

	// Call all elements in the queue.
	let globalObj = window[window['MW-Widget']];
	let queue = globalObj.q;

	if (queue) {
		for (var i = 0; i < queue.length; i++) {
			// Initialize the widget configuration.
			if (queue[i][0].toLowerCase() == 'init') {
				configs = extendObject(configs, queue[i][1]);
				new mijoWidget(configs.feedUrl, configs.id).render();
			}
		}
	}
}

// Add new keys to objects
function extendObject(a, b) {
	for (var key in b)
		if (b.hasOwnProperty(key))
			a[key] = b[key];
	return a;
}

app(window);